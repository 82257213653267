import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';
import translate from 'containers/translate';
import { consumesVatCodes } from 'contexts/VatCodesContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import './VatSelect.scss';

class VatSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vat: this.getVatValue(),
    };

    this.handleVatChange = this.handleVatChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.presetVat !== this.props.presetVat || prevProps.presetVatType !== this.props.presetVatType) {
      this.setState({
        vat: this.getVatValue(),
      });
    }
  }

  handleVatChange(option) {
    const vat = option.value;
    this.setState({ vat }, this.onBlur);
  }

  onBlur() {
    this.props.onVatChange(this.state.vat);
  }

  getVatValue() {
    const vat = this.props.presetVat;
    const vatType = this.props.presetVatType;
    if (vatType === 'NORMAL') {
      return vat;
    }
    return vatType;
  }

  getOptions() {
    const { t } = this.props;

    let vatCodes = this.getAllVatCodes();

    return vatCodes.map((vatCode) => {
      const option = { value: vatCode };
      if (vatCode === 'AUSL0') {
        option.label = t('vat_code_dl');
      } else if (vatCode === 'EXP0') {
        option.label = t('vat_code_export');
      } else if (vatCode === 'FREE') {
        option.label = t('vat_excluded');
      } else if (vatCode === 'REVERSECHARGE') {
        option.label = t('vat_code_reverse_charge');
      } else if (vatCode === 'REVERSECHARGE77') {
        option.label = t('vat_code_reverse_charge_77') + '%';
      } else if (vatCode === 0) {
        option.label = t('vat_code_no_vat');
      } else if (vatCode === 250) {
        option.label = '2.5%';
      } else if (vatCode === 260) {
        option.label = '2.6%';
      } else if (vatCode === 370) {
        option.label = '3.7%';
      } else if (vatCode === 380) {
        option.label = '3.8%';
      } else if (vatCode === 770) {
        option.label = '7.7%';
      } else if (vatCode === 800) {
        option.label = '8%';
      } else if (vatCode === 810) {
        option.label = '8.1%';
      } else if (vatCode === 10000) {
        option.label = t('vat_code_import');
      }
      return option;
    });
  }

  getAllVatCodes() {
    const { selectedPeriod } = this.props;

    if (selectedPeriod.vatType === 'none') {
      return [0, 'REVERSECHARGE', 'REVERSECHARGE77'];
    } else if (selectedPeriod.vatType === 'saldo') {
      return [0, 770, 810, 'REVERSECHARGE', 'REVERSECHARGE77', 'AUSL0', 'EXP0', 'FREE'];
    } else if (selectedPeriod.vatType === 'effective') {
      return [0, 250, 260, 370, 380, 770, 800, 810, 10000, 'REVERSECHARGE', 'REVERSECHARGE77', 'AUSL0', 'EXP0', 'FREE'];
    }
  }

  isSpecialVatCombination() {
    if (!this.props.vatCodes) {
      return false;
    }
    return !this.props.vatCodes.includes(this.state.vat);
  }

  render() {
    const options = this.getOptions();
    const value = options.find((option) => option.value === this.state.vat);

    if (this.props.locked) {
      return <div>{value ? value.label : 0}</div>;
    }

    return (
      <div className={classnames({ warning: this.isSpecialVatCombination() })}>
        <Select
          options={options}
          isClearable={false}
          backspaceRemovesValue={false}
          value={value}
          onChange={this.handleVatChange}
          onBlur={this.onBlur}
          isDisabled={this.props.locked}
          className="Select"
        />
      </div>
    );
  }
}

VatSelect.propTypes = {
  presetVat: PropTypes.any,
  selectedPeriod: PropTypes.object.isRequired,
  presetVatType: PropTypes.any,
  vatCodes: PropTypes.array,
  dossier: PropTypes.object.isRequired,
  onVatChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
};

export default consumesPeriods(consumesVatCodes(translate(VatSelect)));
