import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { consumesBanks, providesBanks } from 'contexts/BanksContext';
import translate from 'containers/translate';
import './BankSelect.scss';

class BankSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bank: props.bank,
    };

    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.bank === prevProps.bank) {
      return;
    }

    this.setState({
      bank: this.props.bank,
    });
  }

  render() {
    if (this.props.remoteBanks.isLoading) {
      return <div />;
    }

    return <div className="BankSelect">{this.renderSelect()}</div>;
  }

  renderSelect() {
    const options = this.props.banks.map((bank) => {
      return {
        value: bank,
        label: `${bank.name}`,
      };
    });
    const value = options.find((option) => this.state.bank && option.value.id === this.state.bank.id);

    return (
      <Select
        type="number"
        value={value}
        onChange={this.handleChange}
        onBlur={this.onBlur}
        ref={(input) => (this.input = input)}
        options={options}
        isClearable={false}
        matchPos={this.state.matchPos}
        isDisabled={this.props.locked}
        className="Select"
      />
    );
  }

  handleChange(option) {
    const bank = option ? option.value : null;

    this.setState({ bank: bank }, this.onBlur);
  }

  onBlur() {
    const { bank } = this.state;

    this.props.onBankChange(bank);
  }
}

BankSelect.defaultProps = {
  creatable: true,
};

BankSelect.propTypes = {
  onBankChange: PropTypes.func.isRequired,
  remoteBanks: RemoteDataPropType,
  banks: PropTypes.array,
  bank: PropTypes.object,
  t: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

export default providesBanks(consumesBanks(translate(BankSelect)));
